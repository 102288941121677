.co-container {
  justify-content: space-around;
  gap: 1rem;
}
.co-container > img {
  width: 9rem;
}

@media (max-width: 999px) {
  .co-container > img {
    width: 7rem;
  }
}
